/* @import "antd/dist/antd.css"; */

.App {
  font-family: sans-serif;
  text-align: center;
}

.ant-list-items {
        max-height: 75vh;
        overflow-y: scroll;
}

.ant-layout-sider {
        background: inherit;
}

.ant-comment-content-author > span.ant-comment-content-author-name {
        color: white;
}

body,
.ant-comment-content-author > span,
.tui-editor-contents  {
        font-family: Microsoft JhengHei, Microsoft YaHei, trebuchet ms,Verdana,verdana ref,segoe ui,Candara,lucida grande,lucida sans unicode,lucida sans,Tahoma,sans-serif;
        font-size: 1rem;
}
    
#root,
.ant-layout.layout{
        height: 100%;
        justify-content: center;
    align-items: center;
}

.container {
        flex-grow: 1;
        position: relative;
        width: auto;
    }

    .container.is-fluid {
        max-width: none;
        padding-left: 32px;
        padding-right: 32px;
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        .container {
            max-width: 960px;
        }
    }

    @media screen and (max-width: 1215px) {
        .container.is-widescreen {
            max-width: 1152px;
        }
    }

    @media screen and (max-width: 1407px) {
        .container.is-fullhd {
            max-width: 1344px;
        }
    }

    @media screen and (min-width: 1216px) {
        .container {
            max-width: 1152px;
        }
    }

    @media screen and (min-width: 1408px) {
        .container {
            max-width: 1344px;
        }
    }
